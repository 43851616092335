import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

export const isLocal = (href = '') => !RegExp('^(https?:)?//', 'i').test(href)
const isMailTo = (href = '') => RegExp('mailto:([^?]+)').test(href)

const leadingSlash = (path = '') =>
  path === '/' ? '' : path.startsWith('/') ? path : `/${path}`

export const localisedHref = (locale, derivedHref) => {
  // Special handling for subscription paths to prevent locale duplication
  if (derivedHref.includes('/my-account/subscription/')) {
    return derivedHref.startsWith('/')
      ? `/${locale}${derivedHref}`
      : `/${locale}/${derivedHref}`
  }

  const prefix = derivedHref.includes(locale) ? '' : `/${locale}`
  return `${prefix}${leadingSlash(derivedHref)}`
}

const LocaleLink = ({ as, href, children, ...props }) => {
  const derivedHref = as ?? href
  const { asPath, locale } = useRouter() || {}
  if (isMailTo(derivedHref)) {
    return React.cloneElement(React.Children.only(children), {
      href: derivedHref
    })
  }
  const hrefLocale =
    isLocal(derivedHref) && locale
      ? localisedHref(locale, derivedHref)
      : derivedHref
  const stripLocale = path => path?.replace(`/${locale}`, '')
  const isCurrentPage =
    stripLocale(asPath) === stripLocale(derivedHref) || asPath === hrefLocale

  return props?.isToReloadPage ? (
    <a href={hrefLocale}>{props?.title}</a>
  ) : (
    <Link passHref href={hrefLocale} {...props}>
      {isCurrentPage
        ? React.cloneElement(React.Children.only(children), {
            'aria-current': true
          })
        : children}
    </Link>
  )
}

LocaleLink.propTypes = {
  ...Link.propTypes
}

export default LocaleLink
