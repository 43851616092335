import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { Wrapper } from './styles'
import Heading from './heading'
import BlockQuote from './block-quote'
import Link from './link'
import Image from './image'
import Bold from './bold'
import Italic from './italic'

const Markdown = ({ text }) => {
  const components = {
    h1: Heading,
    h2: Heading,
    h3: Heading,
    h4: Heading,
    h5: Heading,
    h6: Heading,
    blockquote: BlockQuote,
    a: Link,
    img: Image,
    strong: Bold,
    em: Italic
  }

  return (
    <Wrapper>
      {}
      <ReactMarkdown components={components}>{text}</ReactMarkdown>
    </Wrapper>
  )
}

Markdown.propTypes = {
  text: PropTypes.string.isRequired
}

export default Markdown
